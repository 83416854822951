<template>
  <div>
    <!--  面包屑  -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>库存管理</el-breadcrumb-item>
      <el-breadcrumb-item>采购订单</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <el-form  :model="addPurchaseForm" :rules="addPurchaseFormRules" ref="addPurchaseFormRef"
                label-width="100px">
        <el-row>
          <el-col>
          <el-form-item label="供应商" prop="s_id">
            <el-select v-model="addPurchaseForm.s_id" placeholder="请选择" @change="getcheckList" clearable>
              <el-option
                  v-for="item in supplierList"
                  :key="item.s_id"
                  :label="item.s_company_name"
                  :value="item.s_id">
              </el-option>
            </el-select>
          </el-form-item>
          </el-col>
          <el-col>
            <el-button @click="add" type="primary" :disabled="adddisabled">添加</el-button>
          </el-col>
        </el-row>
        <el-form-item label-width="0px">
          <el-table :data="addPurchaseForm.goodslist" border stripe  >
            <el-table-column type="index"></el-table-column>
            <el-table-column label="商品编码" prop="ga_code"></el-table-column>
            <el-table-column label="商品名称" prop="g_name"></el-table-column>
            <el-table-column label="商品规格" prop="ga_name"></el-table-column>
            <el-table-column label="单位" prop="gu_name" width="50px"></el-table-column>
            <el-table-column label="采购数量" prop="pi_number">
              <template slot-scope="scope">
                <el-input v-model="scope.row.pi_number" @blur="getTotal"></el-input>

              </template>
            </el-table-column>
            <el-table-column label="采购单价" prop="pi_price">
              <template slot-scope="scope">
                <el-input v-model="scope.row.pi_price" @blur="getTotal" @keyup.native="scope.row.pi_price = money(scope.row.pi_price)"></el-input>

              </template>
            </el-table-column>
            <el-table-column label="小计" prop="pi_total_price">
              <template slot-scope="scope">
<!--                {{scope.row.pi_total_price=scope.row.pi_number*scope.row.pi_price*1||0}}-->
                {{ scope.row.pi_total_price=NumberMul(scope.row.pi_number,scope.row.pi_price) || 0}}

              </template>
            </el-table-column>
<!--            <el-table-column label="删除">-->
<!--              <template slot-scope="scope">-->
<!--                <el-button type="danger" @click="delGoodsList(scope.row.ga_id)">删除</el-button>-->
<!--              </template>-->
<!--            </el-table-column>-->
          </el-table>
        </el-form-item>
        <el-form-item label="采购员：" prop="p_purchase_id">
          <el-select v-model="addPurchaseForm.p_purchase_id" placeholder="请选择"
                     clearable class="p_purchase_id">
            <el-option
                v-for="item in userlist"
                :key="item.m_id"
                :label="item.m_name"
                :value="item.m_id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="采购日期：" prop="p_date">
          <el-date-picker v-model="addPurchaseForm.p_date" class="p_date"
                          type="date" value-format="yyyy-MM-dd" placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="其他金额：" prop="p_other_price">
          <el-input v-model="addPurchaseForm.p_other_price" @keyup.native="addPurchaseForm.p_other_price = money(addPurchaseForm.p_other_price)" class="p_other_price"></el-input>
        </el-form-item>
        <el-form-item label="备注：" prop="p_remark">
          <el-input v-model="addPurchaseForm.p_remark" class="p_remark"></el-input>
        </el-form-item>
        <el-form-item label="采购金额：" prop="">
          <el-input v-model="addPurchaseForm.p_total_price" class="p_other_price" disabled></el-input>
        </el-form-item>
<!--        提交-->
        <div style="float: right; padding-bottom:20px; ">
          <el-button @click="addgo">取消</el-button>

          <el-button type="primary" @click="addupPurchase" :loading="addliading">确定</el-button>
        </div>
      </el-form>

    </el-card>

    <el-dialog  title="提示" :visible.sync="addGoodsDialogVisible"
                 width="70%">
      <el-table :data="Goodslist"  stripe @select-all="changeFun" @select="asd" ref="docTable">

        <el-table-column type="selection" width="55" ></el-table-column>
        <el-table-column></el-table-column>
        <el-table-column label="商品编码" prop="ga_code"></el-table-column>
        <el-table-column label="商品名称" prop="g_name"></el-table-column>
        <el-table-column label="商品规格" prop="ga_name"></el-table-column>
      </el-table>
      <!--分页-->
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="querInfo.page"
          :page-sizes="[3,5,10,15]"
          :page-size="querInfo.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addGoodsDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addGoodsDialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      addliading:false,
      querInfo: {
        page: 1,
        limit: 10,
        's_id':''
      },
      total:0,
      //添加表单的form表单
      addPurchaseForm:{
        p_total_price:0
      },
      Goodslist:[],
      //员工列表
      userlist:[],
      //供应商列表
      supplierList:[],
      //选中供应商的数组
      ss_id: '',
      id:{'s_id':''},
      //控制添加商品的显示或隐藏
      addGoodsDialogVisible:false,
      checkBoxData: [],
      //
      adddisabled:true,
      addPurchaseFormRules: {
        s_id: [
          {required: true, message: '请选择供应商', trigger: 'change'},
        ],
        p_purchase_id: [
          {required: true, message: '请选择采购员', trigger: 'change'},
        ],
        p_date: [
          {required: true, message: '请选择采购时间', trigger: 'change'},
        ],
        // p_other_price: [
        //   {required: true, message: '请输入金额', trigger: 'change'},
        // ],
        // p_remark: [
        //   {required: true, message: '请输入备注', trigger: 'change'},
        // ]
      },

    }
  },
  created() {
    this.getsupplierList()
    this.getuserList()
    this.getpurchaseList()


  },
  mounted() {

  },
  methods: {
    //获取采购订单数据
    async getpurchaseList() {
      const {data: res} = await this.$http.get(`purchase/find/${this.$route.query.editid}`)
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      // 把数据列表 赋值
      this.addPurchaseForm = res.data
      this.querInfo.s_id=res.data.s_id
      this.getGoodsList(this.querInfo)
      this.adddisabled=false

    },
    //获取供应商列表
    async getsupplierList() {
      const {data: res} = await this.$http.get('supplier/getMainList')
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      // 把数据列表 赋值
      this.supplierList = res.data
    },
    //获取商品数据
    async getGoodsList(id){
      const {data:res} = await this.$http.get('goods/getList',
          {params:id})
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      this.Goodslist=res.data.data
      this.total=res.data.total
      // setTimeout(() => {
      //   this.pickUp() //需要默认勾选的数据
      // }, 50)
      // this.addGoodsDialogVisible=true

    },
    //获取采购员
    async getuserList() {
      const {data: res} = await this.$http.get('member/getMainList')
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      this.userlist=res.data
    },
    //监听pagesize改变
    handleSizeChange(newSize) {
      this.querInfo.limit = newSize
      this.getGoodsList(this.querInfo)
    },
    //监听pagenum 改变
    handleCurrentChange(newPage) {
      this.querInfo.page = newPage
      this.getGoodsList(this.querInfo)
    },
    // 默认选择中的行
    toggleSelection(rows) {
      if (rows) {
        this.$nextTick(item=>{
          rows.forEach((row) => {
            this.$refs.docTable.toggleRowSelection(row)
          })
        })

      } else {
        this.$refs.docTable.clearSelection()
      }
    },
    // 需要默认勾选的数据
    pickUp() {
      let arr = []
      this.Goodslist.forEach((item) => {
        this.addPurchaseForm.goodslist.forEach((val) => {
          if (val.ga_id === item.ga_id) {
            arr.push(item)
            console.log(arr,'123456')
            // this.radios.push(item.roleID)
          }
        })
      })
      this.toggleSelection(arr)
    },
    // checkboxT(row, rowIndex){
    //   this.Goodslist.forEach((item) => {
    //     this.addPurchaseForm.goodslist.forEach((val) => {
    //       if(val.ga_id === item.ga_id){
    //         return false;//禁用
    //       }else{
    //         return true;//不禁用
    //       }
    //     })
    //   })
    //
    //
    // },

    //供应商选项框发生变化时的回调函数
    getcheckList(){
      this.addPurchaseForm.goodslist=[]
      if (this.addPurchaseForm.s_id == ''){
        this.adddisabled=true
        return true
      }else{
        this.querInfo.s_id=this.addPurchaseForm.s_id
        this.getGoodsList(this.querInfo)
        this.adddisabled=false

      }
    },
    asd(selection, row) {
      let is_exists = true;
      this.addPurchaseForm.goodslist.forEach((item,index)=>{
        if (this.addPurchaseForm.goodslist[index].ga_id===row.ga_id) {
          is_exists = false;
          this.addPurchaseForm.goodslist.splice(index, 1)
        } else {
          // is_exists = false;
        }
      })
      if (is_exists) {
        this.addPurchaseForm.goodslist.push(row)
        is_exists = true;
      }
    },
    add(){
      this.addGoodsDialogVisible=true
      this.pickUp() //需要默认勾选的数据

    },
    handleSelectionChange (selection) {
      console.log(selection)
    },
    //提交form表单
    async addupPurchase(){
      this.$refs.addPurchaseFormRef.validate(async valid => {
        if (!valid) return
        this.addliading=true
        const {data: res} = await this.$http.post('purchase/edit', this.addPurchaseForm)
        this.addliading=false
        if (res.code !== 200) return this.$message.error(res.msg)
        this.$message.success(res.msg)
        this.$router.go(-1)
      })
    },
    addgo(){
      this.$router.go(-1)
    },
    getTotal() {
      this.addPurchaseForm.p_total_price=0
      this.addPurchaseForm.goodslist.forEach((item,index)=>{
        if (item.pi_total_price==0){
          item.pi_total_price=item.pi_number*1*item.pi_price*1
        }else{
          item.pi_total_price=item.pi_number*1*item.pi_price*1
          this.addPurchaseForm.p_total_price += item.pi_total_price*1
        }
      })
    },
    changeFun(selection) {
      let count = 0;
      this.Goodslist.forEach((items,indexs)=>{
        this.addPurchaseForm.goodslist.forEach((item,index)=>{
          if (items.ga_id === item.ga_id){
            this.addPurchaseForm.goodslist.splice(index, 1)
            count++;
          }
        })
      })
      if(count != this.querInfo.limit){
        this.Goodslist.forEach((item,index)=>{
          this.asd([],item)
        })
      }else{
        count = 0;
      }
    },
    delGoodsList(id){
      this.addPurchaseForm.goodslist.forEach((item,index)=>{
        if (item.ga_id===id) {
          this.addPurchaseForm.goodslist.splice(index, 1)
        }
      })
    },
  },
  computed: {
    // totalPrice() {
    //   let result = 0;
    //   for (let i = 0; i < this.addPurchaseForm.goodslist.length; i++) {
    //     this.addPurchaseForm.goodslist[i].pi_total_price =
    //         parseInt(this.addPurchaseForm.goodslist[i].pi_number) * parseInt(this.addPurchaseForm.goodslist[i].pi_price);
    //     result = parseInt(result) + parseInt(this.addPurchaseForm.goodslist[i].pi_total_price);
    //   }
    //
    //   return result
    // },
  },


}
</script>
<style lang="less" scoped>
.p_price,.p_purchase_id,.p_date,.p_remark,.p_other_price{
  width: 200px;
}
</style>
